import { navigate, graphql } from 'gatsby';
import React, { useEffect } from 'react';
import SEO from 'gatsby-plugin-wpgraphql-seo';
import { InlineWidget } from "react-calendly";
import Layout from '~/components/Layout';
import Image from '~/components/atoms/Image';

export default function PricingResult(props) {
  const {
    location: { state },
    data: { wpPage },
  } = props;

  const section = wpPage?.pricingResult?.pricingResult

  useEffect(()=>{
    if (typeof state?.estimate === 'undefined') {
      return navigate('/pricing/');
    }
  }, []);

  return state?.estimate ? (
    <Layout>
      <SEO post={wpPage} />
      <section className="my-20 md:my-36">
        <div className="container">
          {section.heading && <h1 className="text-h1 text-center mb-12">{section.heading}</h1>}

          <div className="grid grid-cols-1 md:grid-cols-12 gap-8 max-w-5xl mx-auto w-full">
            <div className="col-span-1 md:col-span-5 flex flex-col justify-center py-16 px-6 bg-navy-dark text-white">
              {state?.estimate && (
                <div className="space-y-3 mb-12 md:mb-16 text-center max-w-xs mx-auto">
                  <h2 className="text-h6">Potential income per month</h2>
                  <h3 className="text-h2">£{state?.estimate}</h3>
                  {section.disclaimer && <p className="opacity-40 text-xs">{section.disclaimer}</p>}
                </div>
              )}

              {section.assumptions && (
                <div className="max-w-xs mx-auto">
                  {section.assumptions.heading && <h4 className="text-h5 mb-6 text-center">{section.assumptions.heading}</h4>}
                  <div className="flex flex-col justify-center">
                    <div className="text-sm space-y-3 max-w-[16rem]  mx-auto">
                      {section.assumptions.assumptions?.map((item, i) => (
                        <div key={`assumption${i}`} className="grid grid-cols-12 gap-4 items-center">
                          <div className="col-span-3">
                            <div className="aspect-w-1 aspect-h-1">
                              <div>{item.illustration && <Image data={item.illustration} />}</div>
                            </div>
                          </div>
                          <div className="col-span-9">{item.assumption}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

            </div>
            <div className="col-span-1 md:col-span-7 bg-white p-10 border border-opacity-5 border-black flex flex-col">
              {section.calendlyHeading && <h3 className="text-h4 mb-8">{section.calendlyHeading}</h3>}
              <div className="bg-seafoam flex-auto p-5 flex items-center justify-center">
                <InlineWidget
                  styles={{
                    width:'100%',
                    height: '450px'
                  }}
                  pageSettings={{
                    hideEventTypeDetails: true,
                    hideLandingPageDetails: true,
                  }}
                  url="https://calendly.com/city-relay/introduction-call"
                  prefill={{
                    email: state?.email || '',
                    name: state?.full_name || '',
                  }}
                />
              </div>

            </div>
          </div>

        </div>
      </section>
    </Layout>
  ): null
}

export const pageQuery = graphql`
  query pricingResultQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
      ...SEO
      pricingResult {
        pricingResult {
          assumptions {
            heading
            assumptions {
              illustration {
                ... Image
              }
              assumption
            }
          }
          disclaimer
          heading
          calendlyHeading
        }
      }
    }
  }
`
